import { getCaseStatus } from './../api/getCaseStatus';
import { getCaseInfo } from './../api/getCaseInfo';
import { getHealth } from './../api/getHealth';
import { useEffect } from 'react';
import { extractUrlParam } from '../helper/extractUrlParam';
import { useLocation } from '../react-router-hooks';
import {
  useStateValue,
  changeCaseStatusAction,
  changeCaseInfoAction,
  changeCoBrandingAction,
  changeUUIDAndResponseURIAction,
  changeMaintenanceStatusAction
} from './useState';
import { prepareRedirectURL } from '../helper/prepareRedirectURL';
import { useHandleStatus } from './useHandleStatus';
import { routes } from '../constants/constants';
import { useWideScreen } from './useWide';
import { clearLocalStorage } from '../helper/clearLocalStorage';
import { postEvent } from '../api/postEvent';
import { getCoBranding } from '../api/getCoBranding';
import { attemptOpenStore } from '../helper/attemptOpenStore';
import { setItem, removeItem, getItem } from '../helper/storage';
import { uuidIsValid } from '../helper/uuidIsValid';

export const useLocationPrepare = () => {
  const caseStatus = useHandleStatus({});

  const [_, dispatch]: any = useStateValue();
  const { navigate, location } = useLocation();
  const isWideScreen = useWideScreen();

  useEffect(() => {
    let pageUUID = extractUrlParam({ url: location.search, type: 'uuid' });
    const dotApp = extractUrlParam({
      url: location.search,
      type: 'dotApp'
    });

    const referral = extractUrlParam({
      url: location.search,
      type: 'referral'
    });

    let pageResponseURI = extractUrlParam({
      url: location.search,
      type: 'redirect_url'
    });
    const publicSPID = extractUrlParam({
      url: location.search,
      type: 'test_public_spid'
    });

    if (publicSPID) {
      getCoBranding({ uuid: pageUUID, publicSPID }).then(coBranding => {
        dispatch(changeCoBrandingAction({ coBranding }));
      });

      setItem('test_public_spid', publicSPID);
    }

    pageResponseURI = prepareRedirectURL({
      url: pageResponseURI,
      uuid: pageUUID
    });

    postEvent({
      uuid: pageUUID,
      event: dotApp === 'true' ? 'WEB.JUMP.FROMDOTAPP' : 'WEB.JUMP.FROMDOTCOM'
    });

    if (pageUUID) {
      if (!uuidIsValid(pageUUID)) {
        throw new Error('case_uuid does not match UUID v4 pattern.');
      }

      postEvent({
        uuid: pageUUID,
        event: 'WEB.JUMP.INIT',
        details: { responseURI: pageResponseURI }
      });

      if (referral !== 'doublejump') setItem('last_uuid', pageUUID);

      if (referral) {
        let referralEvent: EventName = 'WEB.JUMP.REFERRAL.UNKNOWN';

        if (referral === 'sms') referralEvent = 'WEB.JUMP.REFERRAL.SMS';
        if (referral === 'qr') referralEvent = 'WEB.JUMP.REFERRAL.QRCODE';
        if (referral === 'doublejump')
          referralEvent = 'WEB.JUMP.REFERRAL.DOUBLEJUMP';
        postEvent({
          uuid: pageUUID,
          event: referralEvent,
          details: { referral }
        });
      }

      if (!publicSPID) {
        removeItem('test_public_spid');
      }

      setItem('uuid', pageUUID);
      setItem('redirect_url', pageResponseURI);

      if (!isWideScreen) {
        attemptOpenStore({ uuid: pageUUID, responseURI: pageResponseURI });
      }
    } else {
      pageUUID = getItem('uuid') || '';
      pageResponseURI = getItem('redirect_url') || '';
    }

    if ((!pageUUID || !pageUUID.length) && isWideScreen) {
      navigate(routes.NOCASE);
      return;
    }

    postEvent({
      uuid: pageUUID,
      event: 'WEB.JUMP.LOCATION_CHANGE',
      details: { pathname: location.pathname, search: location.search }
    });

    const getInitialStatus = async () => {
      try {
        if (!isWideScreen) {
          navigate(routes.HOME);
        }

        const health = await getHealth({ uuid: pageUUID });

        if (['BANANA', 'CORONA', 'LOAD'].includes(health?.outage as string)) {
          dispatch(
            changeMaintenanceStatusAction({
              maintenance: health?.outage as string
            })
          );
          return;
        }

        const newCaseStatus = await getCaseStatus({ uuid: pageUUID });
        const newCaseInfo = await getCaseInfo({ uuid: pageUUID });

        if (
          (newCaseInfo?.outage as string) === 'LOAD' &&
          sessionStorage.getItem('queue-place') !== 'true'
        ) {
          dispatch(
            changeMaintenanceStatusAction({
              maintenance: newCaseInfo?.outage as string
            })
          );
          return;
        }

        dispatch(
          changeCaseInfoAction({
            caseType: newCaseInfo?.caseType,
            browserRedirect: newCaseInfo?.browserRedirect,
            hasChallengeResponse: newCaseInfo?.hasChallengeResponse,
            eIDMode: newCaseInfo?.eIDMode
          })
        );

        if (newCaseStatus?.state) {
          const { reason, success } = newCaseStatus;
          // redirect if case is finished
          const browserRedirect = newCaseInfo?.browserRedirect;
          if (reason === 'cancel' || reason === 'timeout' || success) {
            clearLocalStorage();

            setTimeout(() => {
              // check if customer set specific redirect on case creation

              if (browserRedirect) {
                // customer set specific redirect uri case-wise
                window.location.href = prepareRedirectURL({
                  url: browserRedirect,
                  uuid: pageUUID
                });
              } else {
                // customer uses redirect uri frontend-wise
                window.location.href = pageResponseURI || '/';
              }
            }, 4000);

            return;
          }

          // check if customer set specific redirect on case creation

          dispatch(
            changeUUIDAndResponseURIAction({
              uuid: pageUUID,
              responseURI: prepareRedirectURL({
                url:
                  browserRedirect &&
                  newCaseStatus.publicSPID !== '8061986755161725216' &&
                  newCaseStatus.publicSPID !== '918793944076311353'
                    ? browserRedirect
                    : pageResponseURI,
                uuid: pageUUID
              })
            })
          );

          dispatch(changeCaseStatusAction({ caseStatus: newCaseStatus }));

          const coBranding = await getCoBranding({
            uuid: pageUUID,
            publicSPID: getItem('test_public_spid') || newCaseStatus.publicSPID
          });

          dispatch(changeCoBrandingAction({ coBranding }));

          // if (!isWideScreen) {
          //   history.replace(routes.HOME);
          //   return;
          // }

          // if (newCaseStatus.state === "created" && !newCaseStatus.reason) {
          //   history.replace(routes.QR);
          // } else if (newCaseStatus.state !== "created") {
          //   history.replace(routes.IDENT);
          // }
        } else {
          //TODO: [mr] handle error
          clearLocalStorage();

          dispatch(
            changeUUIDAndResponseURIAction({
              uuid: pageUUID,
              responseURI: prepareRedirectURL({
                url: pageResponseURI,
                uuid: pageUUID
              })
            })
          );
        }
      } catch (e) {
        console.warn(e);
      }
    };

    getInitialStatus();
  }, [location]);

  return caseStatus;
};
